import { HomeComp } from "../modules/client/home/components/home";

const Home = () => {
  return (
    <>
      <HomeComp />
    </>
  );
};

export default Home;
